.menu_arrow {
    position: absolute;
    left: 0%;
    top: 1px;
    right: auto;
    bottom: auto;
    z-index: 2;
    display: block;
    width: 0px;
    height: 0px;
    margin-top: 1px;
}

.menu_arrow-wrap {
    display: flex;
    width: 7em;
    height: 0px;
    justify-content: center;
    align-items: flex-end;
    opacity: 0;
}

.menu_arrow-svg {
    width: 1.5em;
    height: 1em;
    color: #fff;
    font-size: 0.9rem;
}