.products_wrap {
    display: flex;
    width: 71.5em;
    justify-content: flex-start;
    align-items: flex-start;
    grid-column-gap: 4.75em;
    grid-row-gap: 4.75em;
}

.products_column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-column-gap: 2.5em;
    grid-row-gap: 2.5em;
}



.products_link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.products_icon {
    width: 2rem;
    height: 2rem;
}

.products_link-text {
    font-size: 0.875rem;
    line-height: 1.4;
    font-weight: 600;
}

.products_link-sub {
    opacity: 0.4;
    font-size: 0.875rem;
    line-height: 1.4;
    font-weight: 500;
}

.products_link-wrap {
    padding-left: 1.2em;
}

.solutions_wrap {
    justify-content: flex-start;
    align-items: flex-start;
}

.solutions_flex {
    display: flex;
    padding-top: 2em;
    justify-content: flex-start;
    align-items: flex-start;
    grid-column-gap: 5em;
    grid-row-gap: 5em;
}

.solutions_column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    grid-column-gap: 1.5em;
    grid-row-gap: 1.5em;
}

.company_flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
}