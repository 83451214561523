.menu_content {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    pointer-events: none;
    display: none;
    width: 100%;
    height: 0px;
    padding-right: 1.6em;
    padding-left: 1.6em;
    justify-content: center;
    align-items: flex-start;
    transform: perspective(1200px) rotate(0deg) perspective(2200px);
}

.menu_bg-wrap {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    z-index: -1;
    display: flex;
    width: 100%;
    height: 0px;
    justify-content: center;
   align-items: flex-start;
}

.menu_bg {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 30em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0.6em;
    background-color: #fff;
}

.menu_dropdown {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: flex;
    padding-right: 1.8em;
    padding-left: 1.8em;
    justify-content: center;
    align-items: flex-start;
    flex: 0 0 auto;
}

.menu_dropdown_content {
    position: absolute;
    z-index: 1;
    overflow: hidden;
    flex: 0 0 auto;
    background-color: transparent;
    color: #0a2540;
    font-size: 0.7rem;
}

.menu_header {
    margin-bottom: 0em;
    opacity: 0.8;
    font-size: 0.8125rem;
    line-height: 1.3;
    font-weight: 500;
    text-transform: uppercase;
}

.menu_dropdown-padding {
    padding: 3.5em;
}

.menu_padding-small {
    padding: 0.8em;
}

.menu_card {
    padding: 2.2em 3.1em;
    border-radius: 0.5em;
    background-color: #f6f9fc;
}

.small-link {
    display: flex;
    width: 19em;
    max-width: 100%;
    align-items: center;
}

.small-link_text {
    font-size: 0.875rem;
    font-weight: 500;
}

.small-link_icon {
    width: 1rem;
    height: 1rem;
    margin-right: 1em;
    color: #88add2;
}

.small-link_icon path {
    fill: currentColor;
}
.products_icon svg {
    width: 100%;
    height: 100%;
}
.small-link:hover .small-link_icon {
    color: var(--main-dark);
}