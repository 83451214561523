.main-navigation__inner-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .menu-link-container {
        padding: 30px 20px;
        cursor: pointer;
    }
}

.menu {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 1rem;
}

.menu_dp-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.menu_dp-link {
    -webkit-transition: opacity 100ms ease;
    transition: opacity 100ms ease;
    cursor: pointer;
}

.menu_link {
    position: relative;
    z-index: 1;
}

.menu_link-padding {
    padding: 1.7em 1.3em;
    cursor: pointer;
}

.menu_link-text {
    font-size: 0.9375rem;
    cursor: pointer;
}


.active {
    z-index: 2;
    pointer-events: auto;
}

.menu_dp-link.active {
    opacity: 0.6;
}