body {
    background-color: #0a2540;
    font-family: Poppins, sans-serif;
    color: #fff;
    font-size: 1vw;
    line-height: 1.6;
    letter-spacing: 0em;
    overflow-x: hidden;
}

p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 1.5em;
}

a {
    font-size: 1em;
    text-decoration: none;
}